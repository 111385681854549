.header-custom-main-blog-mm {
    background-image: linear-gradient(310deg, #141727, #3a416f) !important;
    padding: 20px 0 !important;
}

.mask-main-blog {
    position: absolute;
    background-position: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: linear-gradient(73deg, black, black, #ffffff00);
}

.CONTACTUS-main-blog {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 5px 25px !important;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.SNEAKERRESTORATION {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
}

.banner-custom-main-blog {
    /* background: url(shoose.jpg); */
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    padding: 100px 0;
}

.blog-p-main-blog {
    font-size: 74px;
    color: #fff;
    font-weight: 500;
    line-height: 85px;
    margin: 0 0 30px 0px;
}

.blog-p1-main-blog {
    font-size: 1.25rem;
    color: #fff;
    margin: 0 0 30px 0;
}

.read-more-main-blog {
    border-radius: 6px;
    background: #fff;
    color: #000;
    text-decoration: none;
    height: 35px;
    display: block;
    text-align: center;
    line-height: 32px;
    max-width: 100px;
}

.cus-banner-main-blog {
    max-width: 632px;
    position: relative;
    z-index: 9999;
}

.banner-custom:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-position: 50%;
    content: "";
    background-image: linear-gradient(73deg, black, black, #ffffff00);
}

.shoose-img {
    width: 100%;
}

.Latest-Posts-p-main-blog {
    font-size: 58px;
    color: #344767;
    font-weight: 500;
    line-height: 56px;
    margin: 0 0 15px 0px;
    text-align: center;
}

.Latest-Posts-p1-main-blog {
    color: #67748e;
    font-weight: 400;
    text-align: center;
    margin: 0 0 60px 0;
}

.Latest-Posts-main-blog {
    padding: 60px 0 0 0;
}

.Latest-Posts-p3-main-blog {
    color: #67748e;
    font-weight: 400;
    text-align: left;
    margin: 0 0 15px 0;
}

.Latest-Posts-p2-main-blog {
    font-size: 28px;
    color: #344767;
    font-weight: 500;
    line-height: 29px;
    margin: 0 0 15px 0px;
    text-align: left;
    text-transform: uppercase;
}

.read-more-1-main-blog {
    border-radius: 6px;
    background: #344767;
    color: #ffffff;
    text-decoration: none;
    height: 35px;
    display: block;
    text-align: center;
    line-height: 32px;
    max-width: 100px;
}

.order-div-1-main-blog {
    order: 2;
}

.order-div-2-main-blog {
    order: 1;
}

.latest-two,
.latest-one {
    margin: 0 0 80px 0;
}

.container {
    max-width: 1200px !important;
}

.get-update-p1-main-blog {
    font-size: 28px;
    color: #344767;
    font-weight: 500;
    line-height: 29px;
    margin: 0 0 15px 0px;
    text-align: left;
    text-transform: uppercase;
}

.get-update-p2-main-blog {
    color: #67748e;
    font-weight: 400;
    text-align: left;
    margin: 0 0 0px 0;
}

.your-email-main-blog {
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px 15px 4px 40px;
    width: 100%;
}

.right-fog-main-blog {
    position: relative;
}

.right-fog-main-blog i {
    font-size: 14px;
    width: 100%;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.right-fog-main-blog a {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 5px 25px !important;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.read-more-2-main-blog {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 6px 25px !important;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    margin: 0 0 0 15px;
}

.get-update-right-main-blog {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.get-update-main-blog {
    padding: 20px 0px 20px 0;
}

.button-container {
    padding: 20px;
}

.floating-button-main-blog {
    position: fixed;
    bottom: 35px;
    right: 20px;
    background-color: rgb(24, 22, 22);
    color: rgb(246, 239, 239);
    padding: 10px 20px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    z-index: 1000;
    transition: transform 0.2s;
    transform: scale(1);
}

.floating-button:hover {
    transform: scale(1.1);
}


/* ============================================== */


.carousel-title {
    font-size: 27px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    border: none;
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.carousel-item img {
    max-height: 400px;
    object-fit: cover;
}

.carousel-inner .active {
    display: block;
}

.carousel-indicators button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: gray;
    margin: 5px;
    border: none;
}

button.carousel-control-prev {
    position: absolute;
    bottom: 80px;
}

button.carousel-control-next {
    position: absolute;
    bottom: 80px;
}

.carousel-control-prev-icon-main-blog,
.carousel-control-next-icon-main-blog {
    /* background-color: #ddd; */
    border-radius: 50%;
    padding: 10px;
    position: relative;

}

span.carousel-control-prev-icon-main-blog i {
    color: #000;
    font-size: 25px;
    padding: 10px;
    position: absolute;
    transform: translateY(-50%);
    top: 16px;
    left: 0;
}

span.carousel-control-next-icon-main-blog i {
    color: #000;
    font-size: 25px;
    padding: 10px;
    position: absolute;
    transform: translateY(-50%);
    top: 16px;
    left: 0;
}

input.form-control.form-control-section {
    font-size: 16px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(0, 169, 221);
    border-image: initial;
    border-radius: 5px;
    margin: 5px 0px;
    padding: 14px 10px;
}

.btn-save {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 28px 0px 0px 0px;
    padding: 10px 20px;
}

.popup-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.carousel-control-next-icon {
    background-image: none;
}

.carousel-control-prev-icon {
    background-image: none;
}

.footer-main-blog {
    background-color: #2c3e50;
    color: #ecf0f1;
}

.footer-main-blog h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-main-blog ul {
    padding-left: 0;
}

.footer-main-blog ul li {
    margin-bottom: 10px;
}

.footer-main-blog ul li i {
    margin-right: 10px;
}

.footer-main-blog ul li a:hover {
    color: #3498db;
    text-decoration: underline;
}

.subscribe-main-blog {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 6px 25px !important;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.card-content-main-blog::-webkit-scrollbar {
    width: 0;
}

.right-footer-main-blog {
    position: relative;
}

.right-footer-main-blog i {
    font-size: 14px;
    width: 100%;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
}

button:hover {
    background-color: none !important;
}

button.close-btn-main-blog {
    color: black;
}

button.close-btn:hover {
    background-color: transparent;
}

button.carousel-control-prev:hover {
    background-color: transparent;
}

button.carousel-control-next:hover {
    background-color: transparent;
}

.left-90-main-blog {
    width: 49%;
}

.flex-right-90-main-blog {
    width: 49%;
    padding-left: 30px;
}

.left-90-main-blog img {
    width: 100% !important;
    height: 433px !important;
    object-fit: cover;
}

.outer-div-main-blog .post.repeat-div-main-blog:nth-child(even) .left-90-main-blog {
    order: 2;
}

.outer-div-main-blog .post.repeat-div-main-blog:nth-child(even) .flex-right-90-main-blog {
    order: 1;
    padding-right: 30px;
    padding-left: 0;
}

.h2-1-main-blog {
    color: #344767;
    text-shadow: none;
    margin: 0 0 15px 0;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.375;
    font-family: "Open Sans", sans-serif;
}

.p-cus-main-blog {
    margin: 0 0 15px 0;
    line-height: 1.625;
    font-weight: 400;
    color: #67748e;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
}

.a-tag-main-blog {
    background: #344767;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 8px;
    color: #fff;
    margin: 0 0 0 0;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
}

.post.repeat-div-main-blog {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 60px 0;
}


/* ------------------------- slider ----------------- */

.slider-main-page-main-blog {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: auto;
}

button.prev-main-page-main-blog {
    color: rgb(0, 0, 0);
    background: none;
}

button.prev-main-page-main-blog:hover {
    background: none;
}

button.next-main-page-main-blog:hover {
    background: none;
}

button.next-main-page-main-blog {
    color: rgb(0, 0, 0);
    background: none;
}

.card-content-main-blog {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 10px 0;
    width: 100%;
}

.card-main-page-main-blog {
    flex: 0 0 auto;
    width: 300px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
}

.card-img-main-page-main-blog img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-text-main-page-main-blog {
    padding: 10px;
}

.card-text-main-page-main-blog h2 {
    color: #344767;
    text-shadow: none;
    font-weight: 700;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
}

.card-text-main-page-main-blog p {
    font-family: "Jost";
    font-size: 17px;
}

.btn-main-blog {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 10px;
}

.prev-main-blog {
    left: 10px;
}

.next-main-blog {
    right: 10px;
}


@media(max-width: 991px) {

    .left-90-main-blog,
    .flex-right-90-main-blog {
        width: 100%;
    }

    .outer-div-main-blog .post.repeat-div-main-blog:nth-child(even) .left-90-main-blog {
        order: 1;
        padding-right: 0px;
        padding-left: 0;
    }

    .outer-div-main-blog .post.repeat-div-main-blog:nth-child(even) .flex-right-90-main-blog {
        order: 2;
        padding-right: 0px;
        padding-left: 0;
    }

    .flex-right-90-main-blog {
        padding-left: 0;
    }

    .left-90-main-blog img {
        margin-bottom: 20px !important;
    }
}