.futuristic-background {
    background-color: #0d0d2b; 
    padding: 0; 
    position: relative;
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.4); 
    background-image: linear-gradient(to bottom, #001f3f, #000000); 
    height: 100vh; 
}

.futuristic-carousel .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.futuristic-carousel .owl-prev,
.futuristic-carousel .owl-next {
    background-color: rgba(0, 255, 255, 0.2); 
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.futuristic-carousel .owl-prev:hover,
.futuristic-carousel .owl-next:hover {
    background-color: rgba(0, 255, 255, 0.7); 
}

.futuristic-carousel .owl-prev {
    left: 10px;
}

.futuristic-carousel .owl-next {
    right: 10px;
}

.futuristic-video video {
    width: 100vw;   
    height: 100vh; 
    border: none;   
    object-fit: cover;
}

.futuristic-carousel h1,
.futuristic-carousel p {
    color: #00ffff;
    text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff;
}

.futuristic-carousel .owl-dots {
    display: none;
}
