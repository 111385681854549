.subscription-card {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.subscription-card h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.info-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 18px;
    color: #555;
}

.info-group span:first-child {
    font-weight: bold;
}

.active {
    color: green;
    font-weight: bold;
}

.expired {
    color: red;
    font-weight: bold;
}