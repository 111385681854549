.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.page-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #0054aa;
    cursor: pointer;
}

.page-button.active {
    background-color: #0054aa;
    color: #fff;
    font-weight: bold;
}

.page-button:hover {
    background-color: #00a9dd;
}

.spnspc {
    font-size: 14px;
    color: #000000;
    display: inline-block;
    padding: 10px 0;
}