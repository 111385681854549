/** Login 24 start **/
.login-24-inner {
  padding: 0;
  z-index: 999;
  position: relative;
  min-height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-24 a {
  text-decoration: none;
}

.login-24-inner:before {
  content: "";
  width: 35%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  background-image: linear-gradient(to bottom, #00a9dd, #0054aa);
}

.login-24 .form-check-input:checked {
  display: none;
}

.login-24 .container {
  max-width: 1120px;
}

.login-24 .form-section .form-box {
  float: left;
  width: 100%;
  text-align: left;
  position: relative;
}

.login-24 h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Jost', sans-serif;
}

.form-check-input:checked {
  background-color: #22c715 !important;
  border-color: #22c715 !important;
}

.form-check-input {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.form-switch .form-check-input {
  width: 3rem !important;
  height: 1.4rem !important;
  background-image: url("./images/download.svg") !important;
}

.form-check.form-switch {
  display: flex;
  justify-content: center;
}

table.table.table-bordered.mt-3 {
  text-align: center;
}

.form-check-input:focus {
  color: white !important;
  outline: none !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.75) !important;
}

.custom-switch {
  width: 60px;
  height: 34px;
}

.custom-switch::before {
  width: 26px;
  height: 26px;
}

.login-24 .bg-img {
  background: url(./images/blog-img1.png);
  background-size: cover;
  top: 0;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
}

.login-24 .login-box {
  background: #fff;
  margin: 0 auto;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-24 .form-section {
  padding: 60px;
  border-radius: 10px 0 0 10px;
  text-align: left;
}

.login-24 .pad-0 {
  padding: 0;
}

.login-24 label {
  color: #424242;
  font-size: 16px;
  margin-bottom: 5px;
}

.login-24 .form-section p {
  color: #424242;
  font-size: 16px;
  margin-bottom: 40px;
}

.login-24 .form-info {
  padding: 0;
}

.login-24 .form-section ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.login-24 .form-section .social-list li {
  display: inline-block;
  margin-bottom: 5px;
}

.login-24 .form-section .thembo {
  margin-left: 4px;
}

.login-24 .form-section h1 {
  font-size: 27px;
  font-weight: 600;
  color: #0054aa;
}

.login-24 .form-section h3 {
  margin: 0 0 40px;
  font-size: 23px;
  font-weight: 400;
  color: #121212;
}

.login-24 .form-section .form-group {
  margin-bottom: 25px;
}

.login-24 .form-section .form-control {
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  height: 55px;
  color: #424242;
  border-radius: 3px;
  border: 1px solid transparent;
  background: #efefef;
}

.login-24 .form-section .checkbox .terms {
  margin-left: 3px;
}

.login-24 .form-section .btn-md {
  cursor: pointer;
  padding: 15.5px 50px 14.5px 50px;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Jost', sans-serif;
  border-radius: 3px;
}

.login-24 .form -section input[type=checkbox],
input[type=radio] {
  margin-right: 3px;
}

.login-24 .form-section button:focus {
  outline: none;
  outline: 0 auto -webkit-focus-ring-color;
}

.login-24 .form-section .btn-theme.focus,
.btn-theme:focus {
  box-shadow: none;
}

.login-24 .form-section .btn-theme {
  background: #0054aa;
  border: none;
  color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-24 .form-section .btn-theme:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #00a9dd;
}

.login-24 .form-section .terms {
  margin-left: 3px;
}

.login-24 .form-section .form-check {
  float: left;
  margin-bottom: 0;
  padding-left: 0;
}

.login-24 .form-section .form-check a {
  color: #424242;
  float: right;
}

.login-24 .form-section .form-check-input {
  position: absolute;
  margin-left: 0;
}

.login-24 .form-section .form-check label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  margin-left: -25px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #efefef;
}

.login-24 .form-section .form-check-label {
  padding-left: 25px;
  margin-bottom: 0;
  font-size: 16px;
  color: #424242;
}

.login-24 .form-section .checkbox-theme input[type="checkbox"]:checked+label::before {
  background-color: #0054aa;
  border-color: #0054aa;
}

.login-24 .form-section input[type=checkbox]:checked+label:before {
  font-weight: 600;
  color: #fff;
  line-height: 15px;
  font-size: 14px;
  content: "\2713";
  padding-left: 3px;
}

.login-24 .form-section input[type=checkbox],
input[type=radio] {
  margin-top: 4px;
}

.login-24 .form-section a.forgot-password {
  font-size: 16px;
  color: #424242;
  float: right;
}

.login-24 .social-list a {
  text-align: center;
  display: inline-block;
  font-size: 18px;
  margin-right: 20px;
  color: #424242;
}

.login-24 .social-list a:hover {
  color: #0054aa;
}

@media (max-width: 992px) {
  .login-24 .form-section {
    padding: 60px 60px 60px;
  }

  .login-24-inner:before {
    background: none;
  }

  .login-24 .login-box {
    max-width: 500px;
    margin: 0 auto;
  }

  .login-24 .bg-img {
    display: none;
  }
}

@media (max-width: 768px) {
  .login-24 .form-section {
    padding: 40px 30px;
  }
}

/** Login 24 end **/


.side-bar {
  background-color: #0054aa;
  min-height: 100vh;
  max-height: calc(100% - 0px);
  transform: translateX(0%);
  width: 260px;
  position: fixed;
  left: 0;
  top: 0;
  transition: .5s;
}

.open-class .side-bar {
  left: -260px;
}

.open-class .dasboard-all .container-fluid {
  padding-left: 30px;
}

h1.side-bar-heading {
  color: #fff;
  text-align: center;
  padding: 15px 0 0 0;
}

.link-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link-ul li a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 5px;
  border-radius: 10px;
  margin: 0 0 5px 0;
  transition: 0.3s;
}

.link-ul li a.active,
.link-ul li a:hover {
  background: #00a9dd;
  color: #fff;
  padding: 8px;
}

.link-div {
  padding: 0 20px;
  margin-top: 32px;
}

.logo -top {
  max-width: 140px;
  margin: auto;
  display: block;
  margin-top: 40px;
  margin-bottom: 25px;
}

.dasboard-all .container-fluid {
  padding-left: 290px;
  padding-right: 30px;
  transition: .5s;
}

.link-ul li a i {
  margin: 0 10px 0 0;
}

.dasboard-all.top-header {
  background: #fff;
  padding: 15px 0;
}

.header-flex ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tog-bt {
  display: block;
  padding: 0px 10px;
  border-radius: 10px;
  margin: 0 0 0px 0;
  transition: 0.3s;
  background: #0054aa;
  color: #fff;
  border: none;
  height: 35px;
  width: 45px;
  text-align: center;
  line-height: 35px;
}

.line-1 {
  height: 2px;
  width: 30px;
  background: #fff;
  display: block;
  margin: 4px 0;
  border-radius: 23px;
}

.log-out-bt {
  cursor: pointer;
  padding: 5px 30px 5px 30px;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  font-family: 'Jost', sans-serif;
  border-radius: 100px;
  background: #0054aa;
  border: none;
  color: #ffffff;
}

i.fa-solid.fa-caret-left {
  color: #fff;
}

.top-header i.fa-solid.fa-bars {
  display: none;
}

.open-class .top-header i.fa-solid.fa-caret-left {
  display: none;
}

.open-class .top-header i.fa-solid.fa-bars {
  display: block;
}

.last-table {
  overflow: auto;
}

.last-table table {
  width: 100%;
  min-width: 800px;
}

.last-table table thead tr th {
  font-size: 16px;
  color: #ffffff;
  padding: 15px 15px;
  background: #0054aa;
  font-weight: bold;
}

.last-table table tbody tr td {
  font-size: 16px;
  color: #535353;
  padding: 15px 15px;
  background: #ffffff;
  font-weight: 400;
}

.last-table table thead tr th:nth-child(1) {
  border-top-left-radius: 12px;
}

.last-table table thead tr th:last-child {
  border-top-right-radius: 12px;
}

.last-table table tbody tr:last-child td:nth-child(1) {
  border-bottom-left-radius: 12px;
}

.last-table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.content-dasboard {
  padding: 30px 0;
}

.last-table table tbody tr:nth-child(even) td {
  background: #eeeeee;
}

.last-table table tbody tr:hover td {
  background: #00a9dd14;
}

.search-input .same-input {
  border: 1px solid #f7f7f7;
  background: #fff;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  font-size: 16px;
  padding: 0 15px;
  max-width: 140px;
  margin: 0 0 0 0;
  color: #535353;
}

.search-input .same-input:hover,
.search-input .same-input:focus {
  outline: 2px solid #00a9dd;
}

.input-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.input-ul li {
  margin: 0 15px 15px 0;
}

.bt-search {
  border: 1px solid #f7f7f7;
  background: #0054aa;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  font-size: 16px;
  padding: 0 15px;
  max-width: 160px;
  margin: 0 0 0 0;
  color: #ffffff;
}

.all-label {
  color: #535353;
  font-size: 16px;
  margin: 0 5px 0 0;
}

body {
  background: #eeeeee;
}

.eye-div {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 22px;
}

p.error_text {
  color: red !important;
  margin: 0px !important;
}


.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(150, 150, 150, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


@media (max-width: 992px) {
  .login-24 .form-section {
    padding: 60px 60px 60px;
  }

  .login-24-inner:before {
    background: none;
  }

  .login-24 .login-box {
    max-width: 500px;
    margin: 0 auto;
  }

  .login-24 .bg-img {
    display: none;
  }
}



@media(min-width: 768px) {
  .abs-bk {
    display: none;
  }
}

@media(max-width: 767px) {
  .dasboard-all .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .open-class .dasboard-all .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .side-bar {
    left: -270px;
  }

  .open-class .side-bar {
    left: 0px;
  }

  .abs-bk i {
    color: #fff;
    font-size: 15px;
    position: fixed;
    right: -10px;
    top: 110px;
    background: #76b51b;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 30px;
    line-height: 20px;
  }
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.date-picker-icon {
  font-size: 18px;
  color: #333;
}

.date-picker-input {
  border: none;
  width: 120px;
}
