* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.hero-section {
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}

.bg-gradient-dark {
    background-image: linear-gradient(310deg, #141727, #3a416f);
}

.contact-us {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    padding: 5px 25px !important;
    font-weight: 700 !important;
    font-size: 12px !important;
}

.mask {
    position: absolute;
    background-position: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.page-header {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}

footer.footer.py-5.draggable a {
    text-decoration: none;
}

a.nav-link {
    color: #fff;
    padding: 0px 8px !important;
}


.navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.nav-link.dropdown-toggle::after {
    display: none;
}

.nav-link i {
    margin-left: 5px;
}

.dropdown-menu {
    display: none;
    transition: all 0.3s ease;
}

.nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
}

input.form-control-blog1212 {
    width: 100%;
    padding: 4px 15px 4px 40px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
}

.input-group111 {
    position: relative;
}

span.input-group-text1111 {
    position: absolute;
    left: 15px;
    top: 3px;
}

button.contact-us.ui-bt {
    margin: 0 !important;
}

a.navbar-brand {
    color: #fff !important;
    font-size: 20px !important;
}

.post.repeat-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 60px 0;
}

.left-90 {
    width: 49%;
}

.flex-right-90 {
    width: 49%;
    padding-left: 30px;
}

.left-90 img {
    width: 100% !important;
    height: 433px !important;
    object-fit: cover;
}

.outer-div .post.repeat-div:nth-child(even) .left-90 {
    order: 2;
}

.outer-div .post.repeat-div:nth-child(even) .flex-right-90 {
    order: 1;
    padding-right: 30px;
    padding-left: 0;
}

.h2-1 {
    color: #344767;
    text-shadow: none;
    margin: 0 0 15px 0;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.375;
    font-family: "Open Sans", sans-serif;
}

.a-tag {
    background: #344767;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 8px;
    color: #fff;
    margin: 0 0 0 0;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
}

.p-cus {
    margin: 0 0 15px 0;
    line-height: 1.625;
    font-weight: 400;
    color: #67748e;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
}

.popup-main-1 {
    width: 100% !important;
    max-width: 90% !important;
    max-height: 95vh !important;
}

.popup-main-1 h2 {
    color: #344767;
    text-shadow: none;
    margin: 0 0 15px 0;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.375;
    font-family: "Open Sans", sans-serif;
}

.popup-main-1 p {
    margin: 0 0 15px 0;
    line-height: 1.625;
    font-weight: 400;
    color: #67748e;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    margin: 15px 0 15px 0;
}

.popup-main-1 {
    padding: 30px !important;
}

.edit-inner-input {
    width: 100%;
    display: block;
    border: 1px solid #67748e;
    padding: 10px 15px;
    border-radius: 8px;
    color: #344767;
    text-shadow: none;
    margin: 0 0 15px 0;
    font-weight: 700;
    font-size: 1.875rem;
    font-family: "Open Sans", sans-serif;
}

.edit-textarea-input {
    width: 100%;
    display: block;
    border: 1px solid #67748e;
    padding: 10px 15px;
    border-radius: 8px;
    line-height: 1.625;
    font-weight: 400;
    color: #67748e;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    height: 300px;
}

.floating-button-save {
    left: 45%;
    position: fixed;
    top: 2px;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.floating-button-Sign {
    left: 36%;
    position: fixed;
    top: 2px;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.fixed-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}


.footer {
    background-color: #2c3e50;
    color: #ecf0f1;
}

.footer h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.footer ul {
    padding-left: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li i {
    margin-right: 10px;
}

.footer ul li a:hover {
    color: #3498db;
    text-decoration: underline;
}

button.subscribe-btn.w-100 {
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
    border: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 6px 25px !important;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    margin-left: 0px;
}

.right-footer {
    position: relative;
}

.right-footer i {
    font-size: 14px;
    width: 100%;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
}

.your-email {
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px 15px 4px 40px;
    width: 100%;
}


@media(max-width: 991px) {

    .left-90,
    .flex-right-90 {
        width: 100%;
    }

    .outer-div .post.repeat-div:nth-child(even) .left-90 {
        order: 1;
        padding-right: 0px;
        padding-left: 0;
    }

    .outer-div .post.repeat-div:nth-child(even) .flex-right-90 {
        order: 2;
        padding-right: 0px;
        padding-left: 0;
    }

    .flex-right-90 {
        padding-left: 0;
    }

    .left-90 img {
        margin-bottom: 20px !important;
    }
}