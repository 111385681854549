h2,
h3 {
    margin-bottom: 15px;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.input-group {
    margin-bottom: 10px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.referral-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.referral-table th,
.referral-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.referral-table th {
    background-color: #f2f2f2;
}

.form-group-modal {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.input-group label {
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
}

.input-group input[type="number"],
.input-group input[type="text"],
.input-group select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.input-group input[type="number"]:focus,
.input-group input[type="text"]:focus,
.input-group select:focus {
    border-color: #007bff;
    outline: none;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="button"].btn-danger {
    background-color: #dc3545;
    margin-right: 10px;
}

button[type="button"].btn-danger:hover {
    background-color: #c82333;
}

button[type="submit"] {
    background-color: #007bff;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}